/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    position: relative;
    font-family: 'Roboto', sans-serif;
    /* background: #f4f7fa; */
}

.min-vh-81 {
    min-height: 81vh !important;
  }
/* .card {
    display: flex;
    flex-direction: column;
    border-radius: .5rem;
    background-color: #fff;
    margin: 1.25rem 0 1.25rem 0;
    box-shadow: 0 .25rem .5rem #e6edef;
}

.card .card-head {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #edf1f7;
    font-size: 1rem;
    font-weight: 600;
}

.card .card-body {
    padding: 1rem 1.5rem; 
} */

/* .bg-light>.border>.border-dark>.rounded-2>.border-dark {
    border: none !important;
}

html {
    height: -webkit-fill-available;
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

main {
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    height: -webkit-fill-available;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
}

a {
    cursor: pointer;
}

.borderTable::ng-deep table {
    border: 1px solid #dee2e6 !important;
} */

/* .dropdown:hover > .dropdown-menu {
    display: block;
}

.dropdown > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg);
}  */

.divider:after, .divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

.min-vh-82 {
    min-height:82vh
}
.min-vh-93 {
    min-height:93.2vh
}
.min-vh-6 {
    min-height: 6.8vh;
}

.min-vh-93 {
    min-height: 93.2vh !important;
}
.min-vh-74 {
    min-height: 74.2vh;
}
.min-vh-82 {
    min-height:82.8vh
}
.min-vh-55 {
    min-height: 55vh;
}
.min-vh-88 {
    min-height: 87.7vh
}
.min-vh-90 {
    min-height: 90vh
}
.fs-12 {
    font-size: 12px;
}
.w-19 {
    width: 19%;
}
.max-vh-70 {
    max-height: 70vh !important;
}
.overflow-y-auto {
    overflow: auto !important;
  }
.lh-3 {
    line-height: 3 !important;
}
.text-decoration-none:hover {
    text-decoration: underline !important;
}
.w-2 {
    width: 2%;
}

.mt-2 {
    margin-top: 2rem !important;
}

tms-add-user-table {
    border: 1px solid #c5c5c5;
    min-height: 400px;
    margin-bottom: 30px;
    display: flex;
    padding-bottom: 15px;
}

.tab-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 0 0 20%;
    color: #fff;
    font-size: 14px;
}

.tab-left>div.tms-add-user-panel-active {
    color: #2d78be;
    vertical-align: top;
    background-color: #fff;
    padding: 0.75em 0.75em 1.1em;
    border-bottom: 3px solid #00a4d8;
}

.tab-left>div {
    display: inline-block;
    font: 1em Arial,Helvetica;
    color: #000;
    text-decoration: none;
    vertical-align: top;
    background-color: #f2f5f7;
    padding: 0.75em 0.75em 1.1em;
    border-bottom: 3px solid #f2f5f7;
    cursor: pointer;
    width: 100%;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

div.p-dataview-grid div.grid{
    display: flex;
    flex-wrap: wrap;
}

div.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: 0 !important;
}

div.p-dataview-header {
    padding: 0 !important;
}

div.p-picklist div.p-picklist-buttons.p-picklist-source-controls {
    display: none;
}

div.p-picklist div.p-picklist-buttons.p-picklist-target-controls {
    display: none;
}

div.p-picklist div.p-picklist-list-wrapper.p-picklist-source-wrapper > div.p-picklist-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

div.p-picklist div.p-picklist-list-wrapper.p-picklist-target-wrapper > div.p-picklist-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

div.p-picklist div.p-picklist-list-wrapper.p-picklist-source-wrapper > ul.p-picklist-list.p-picklist-source li.p-picklist-item {
    padding-top: 0;
    padding-bottom: 0;
}

div.p-picklist div.p-picklist-list-wrapper.p-picklist-target-wrapper > ul.p-picklist-list.p-picklist-target li.p-picklist-item {
    padding-top: 0;
    padding-bottom: 0;
}
.p-toast-detail {
    white-space: pre-line;
 }

 .p-dropdown {
    min-width: 15rem;
    width: 18rem;
 }

 .p-multiselect {
    min-width: 15rem;
    width: 18rem;
 } 
 app-product-drights .p-multiselect {
    min-width: 15rem;
    width: 75%;
 }
 /* p-table cell datepicker popup show appearover table*/
 .p-datatable>.p-datatable-wrapper {
    overflow: visible!important;
}
/*TMS-351:Author Module/ and title manager html editor have file/video upload icon, need to hide*/
.angular-editor-button[title="Insert Image"] ,.angular-editor-button[title="Insert Video"]{
    display:none
}

.p-button.p-button-icon-only.p-button-rounded {
    height: 1rem !important;
}
.popover-html-content-body {
    max-height: 400px;
    overflow-y: scroll;
}

.has-error {  
    border: 1px solid red !important;
}

.p-treetable .p-treetable-tbody >tr >td {
    padding: 4px 8px;
}

.p-treetable .p-treetable-tbody >tr  {
    line-height: 1.2em;
}

/* .p-treetable .p-treetable-toggler  {
    display: flex;
    align-items: center;
    padding: 3px 8px;
} */

app-production-cost .p-multiselect {
    min-width: 15rem;
    width: 75%;
 }

.autocomplete-c .p-autocomplete-pannel{
    min-height: 200px;
    min-width: 100%;
}

.autocomplete-c .p-inputtext{
    height: 40px;
    width: 14rem;
    padding: 5px;
    font-size: 14px;
}

.dd-xs .p-dropdown{
    min-width: 6rem !important;
    width: 6rem !important;
} 
.dd-s .p-dropdown{
    min-width: 9rem !important;
    width: 9rem !important;
}
/* .dd .p-dropdown-panel{
    min-height: 200px;
    min-width: 100%;
}   */
 
.dd .p-inputtext{
    height: 35px;
    padding: 7px 5px 0px 5px;  
} 

